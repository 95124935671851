exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-xx-tsx": () => import("./../../../src/pages/5xx.tsx" /* webpackChunkName: "component---src-pages-5-xx-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-unsupported-browser-tsx": () => import("./../../../src/pages/unsupported-browser.tsx" /* webpackChunkName: "component---src-pages-unsupported-browser-tsx" */),
  "component---src-static-pages-home-page-v-2-home-page-v-2-tsx": () => import("./../../../src/static_pages/HomePageV2/HomePageV2.tsx" /* webpackChunkName: "component---src-static-pages-home-page-v-2-home-page-v-2-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-apply-page-tsx": () => import("./../../../src/templates/ApplyPage.tsx" /* webpackChunkName: "component---src-templates-apply-page-tsx" */),
  "component---src-templates-batmaid-about-page-tsx": () => import("./../../../src/templates/batmaid/AboutPage.tsx" /* webpackChunkName: "component---src-templates-batmaid-about-page-tsx" */),
  "component---src-templates-batmaid-contact-us-page-tsx": () => import("./../../../src/templates/batmaid/ContactUsPage.tsx" /* webpackChunkName: "component---src-templates-batmaid-contact-us-page-tsx" */),
  "component---src-templates-batmaid-help-page-tsx": () => import("./../../../src/templates/batmaid/HelpPage.tsx" /* webpackChunkName: "component---src-templates-batmaid-help-page-tsx" */),
  "component---src-templates-city-page-tsx": () => import("./../../../src/templates/CityPage.tsx" /* webpackChunkName: "component---src-templates-city-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-footer-tsx": () => import("./../../../src/templates/Footer.tsx" /* webpackChunkName: "component---src-templates-footer-tsx" */),
  "component---src-templates-generic-service-page-tsx": () => import("./../../../src/templates/GenericServicePage.tsx" /* webpackChunkName: "component---src-templates-generic-service-page-tsx" */),
  "component---src-templates-global-content-page-tsx": () => import("./../../../src/templates/GlobalContentPage.tsx" /* webpackChunkName: "component---src-templates-global-content-page-tsx" */),
  "component---src-templates-global-landing-page-tsx": () => import("./../../../src/templates/GlobalLandingPage.tsx" /* webpackChunkName: "component---src-templates-global-landing-page-tsx" */),
  "component---src-templates-help-article-tsx": () => import("./../../../src/templates/HelpArticle.tsx" /* webpackChunkName: "component---src-templates-help-article-tsx" */),
  "component---src-templates-help-category-tsx": () => import("./../../../src/templates/HelpCategory.tsx" /* webpackChunkName: "component---src-templates-help-category-tsx" */),
  "component---src-templates-mobile-app-store-redirect-tsx": () => import("./../../../src/templates/MobileAppStoreRedirect.tsx" /* webpackChunkName: "component---src-templates-mobile-app-store-redirect-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/ServicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */),
  "component---src-templates-sponsor-tsx": () => import("./../../../src/templates/Sponsor.tsx" /* webpackChunkName: "component---src-templates-sponsor-tsx" */),
  "component---src-templates-voucher-tsx": () => import("./../../../src/templates/Voucher.tsx" /* webpackChunkName: "component---src-templates-voucher-tsx" */),
  "component---src-templates-whats-included-page-tsx": () => import("./../../../src/templates/WhatsIncludedPage.tsx" /* webpackChunkName: "component---src-templates-whats-included-page-tsx" */)
}

